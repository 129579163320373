<template>
  <div class="content">
    <div class="content__party">
      <h1 class="title title--big title--color">Мероприятия</h1>
    </div>
    <div class="content__party">
      <div class="table-limiter">
        <div class="table-limiter__text">Количество записей на странице:</div>
        <div class="table-limiter__select">
          <dropdown
            class="my-dropdown-toggle"
            :options="limitOptions"
            :selected="limitSelected"
            :closeOnOutsideClick="true"
            @updateOption="updateLimiter"
          >
          </dropdown>
        </div>
      </div>
      <div class="table-limiter">
        <div class="table-limiter__text">
          Показаны записи <span>{{ returnEnd() > 0 ? returnStart() : 0 }} - {{ returnEnd() }}</span> из
          <span>{{ pagination.count }}</span>
        </div>
      </div>
    </div>
    <v-client-table
      :data="table.items"
      :columns="table.columns"
      :options="table.options"
      ref="eventsGPBTable"
      class="table-default table-default--minw"
    >
      <div slot="is_active" slot-scope="props">
        {{ props.row.is_active ? 'Активно' : 'Не активно' }}
      </div>
      <div slot="tooltip" slot-scope="props">
        <v-popover
          placement="left-start"
        >
          <button class="tooltip-target b3">
            <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9 4C10.1046 4 11 3.10457 11 2C11 0.89543 10.1046 0 9 0C7.89543 0 7 0.89543 7 2C7 3.10457 7.89543 4 9 4Z"
                fill="#2B93E7"/>
              <path
                d="M16 4C17.1046 4 18 3.10457 18 2C18 0.89543 17.1046 0 16 0C14.8954 0 14 0.89543 14 2C14 3.10457 14.8954 4 16 4Z"
                fill="#2B93E7"/>
              <path
                d="M2 4C3.10457 4 4 3.10457 4 2C4 0.89543 3.10457 0 2 0C0.89543 0 0 0.89543 0 2C0 3.10457 0.89543 4 2 4Z"
                fill="#2B93E7"/>
            </svg>
          </button>
          <template slot="popover">
            <ul class="tooltip__list">
              <li class="tooltip__item">
                <img src="@/assets/img/edit-icon.svg" alt="Edit icon">
                <router-link
                  :to="{ name: 'event-gpb', params: { id: props.row.id } }"
                  class="tooltip__btn"
                >
                  Редактировать
                </router-link>
              </li>
              <li class="tooltip__item">
                <img src="@/assets/img/trash-icon.svg" alt="Delete icon">
                <button @click="deleteRequest(props.row.id)" class="tooltip__btn">Удалить</button>
              </li>
            </ul>
          </template>
        </v-popover>
      </div>
    </v-client-table>
    <paginate
      v-if="pagination.pages > 1"
      v-model="pagination.page"
      :page-count="pagination.pages"
      :clickHandler="clickPaginationCallback"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__prev'"
      :next-class="'pagination__next'"
      :next-link-class="'pagination__link-next'"
      :prev-link-class="'pagination__link-prev'"
    >
    </paginate>
  </div>
</template>

<script>
import dropdown from 'vue-dropdowns';

export default {
  name: 'EventsGPB',
  components: {
    dropdown,
  },
  data() {
    return {
      table: {
        items: [],
        columns: ['id', 'name', 'is_active', 'tooltip'],
        options: {
          headings: {
            id: 'ID заявки',
            name: 'Название',
            is_active: 'Активность',
            tooltip: ''
          },
          pagination: {show: false},
          sortable: [],
          perPage: 20,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Нет подходящих записей',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: () => 'table-default__row',
        },
      },
      limitSelected: { name: 20 },
      limitOptions: [
        { name: 20 },
        { name: 40 },
        { name: 60 },
        { name: 80 },
        { name: 100 },
      ],
      pagination: {
        pages: 1,
        page: 1,
        count: 0,
        prevLink: '',
        nextLink: ''
      },
    };
  },
  mounted() {
    this.fetchItems(1, this.limitSelected.name);
  },
  methods: {
    fetchItems(page = 1, page_size) {
      this.pagination.page = page;
      this.$store.dispatch('admin/cardsGpb/GET_EVENTS', { page, page_size })
        .then(response => {
          this.table.items = response.data.results;
          this.pagination.pages = response.data.pages;
          this.pagination.count = response.data.count;
        })
        .catch(error => {
          console.error(error.response);
        });
    },
    updateLimiter(data) {
      this.limitSelected.name = data.name;
      this.$refs.eventsGPBTable.setLimit(data.name);
      this.fetchItems(this.pagination.page, this.limitSelected.name, this.status);
    },
    clickPaginationCallback(page) {
      this.fetchItems(page, this.limitSelected.name, this.status);
    },
    returnStart() {
      return (this.pagination.page * this.table.items.length) - (this.table.items.length - 1);
    },
    returnEnd() {
      return this.returnStart() + this.table.items.length - 1;
    },
    deleteRequest(id) {
      this.$store.dispatch('admin/cardsGpb/DELETE_EVENT', id)
        .then(() => {
          document.body.click();
          this.$notify({
            type: 'success',
            title: 'Уведомление',
            text: 'Мероприятие успешно удалено'
          });
          this.fetchItems(1, this.limitSelected.name);
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Внимание!',
            text: 'Ошибка удаления заявки'
          });
        });
    }
  }
};
</script>
